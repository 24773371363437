import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tag from '../tag/Tag'; // Ensure this path is correct
import './ViewQuestion.css';
import axios from 'axios';
import API_ROUTES from '../apiRoutes';

const QuestionList = () => {
  const [questions, setQuestions] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const limit = 10; // Default to 10 items per page

  useEffect(() => {
    fetchQuestions(start);
  }, [start]);

  const fetchQuestions = async (start) => {
    setLoading(true);
    try {
      const end = start + limit - 1; // Calculate the end index
      const response = await axios.get(API_ROUTES.HOST + '/get-questions', {
        params: {
          start: start,
          end: end,
        },
        withCredentials: true,
      });

      const data = response.data.map((question) => ({
        ...question,
        tags: Array.isArray(question.tags) ? question.tags : [question.tags],
      }));

      setQuestions((prevQuestions) => [...prevQuestions, ...data]);
      setHasMore(data.length === limit); // Check if there are more questions to load
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setStart((prevStart) => prevStart + limit);
  };

  const renderLoadMoreButton = () => {
    if (hasMore && !loading) {
      return (
        <div>
          <button onClick={loadMore} className="load-more-button">
            Load More
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {questions.length > 0 ? (
        questions.map((question) => (
          <div style={{ display: 'grid' }} key={question.id}>
            <Link
              to={`/question/${question.id}`}
              className="card card-home txt-decoration-none"
              style={{ color: 'inherit', margin: '5px' }}
            >
              <h3 className="card-color">{question.question}</h3>
              <div className="tags block-tag">
                <span className="criteria">Criteria asked to consider while designing</span>
                {question?.tags?.length > 0 &&
                  question.tags.map((tag, index) => (
                    <Tag key={`${tag}`} label={tag} disableVoting={true} removeVote={true}/>
                  ))}
              </div>
              <div className="font14px display-flex postedby-color">
                  <div className="marginTop24">
                    <span className="font-small">Posted By </span>: <strong>{question.username}</strong> <br></br>
                    <small>{new Date(question.created).toLocaleString()}</small>
                  </div>
                </div>
            </Link>
          </div>
        ))
      ) : (
        <p style={{ textAlign: 'center' }}>No questions have been posted yet!</p>
      )}
      {loading && <p>Loading...</p>}
      {renderLoadMoreButton()}
    </div>
  );
};

export default QuestionList;
