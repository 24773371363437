import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>Privacy Policy</h1>
        <p style={paragraphStyle}><strong>Effective Date:</strong> 31 August 2024</p>

        <h2 style={subHeadingStyle}>1. Introduction</h2>
        <p style={paragraphStyle}>
          Welcome to systemdesignexpert.com. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our Website.
        </p>

        <h2 style={subHeadingStyle}>2. Information We Collect</h2>
        <p style={paragraphStyle}>
          When you sign up and use our Website, we may collect the following information:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}><strong>Email Address:</strong> We collect your email address as part of the Google authentication process. This allows us to create and manage your account on the Website.</li>
          <li style={listItemStyle}><strong>Profile Image:</strong> We collect the profile image associated with your Google account to display it on your profile and alongside your posts, comments, and other activities on the Website.</li>
          <li style={listItemStyle}><strong>User-Generated Content:</strong> Any questions, answers, comments, votes, edits, or deletions you make on the Website are stored and associated with your account.</li>
        </ul>

        <h2 style={subHeadingStyle}>3. How We Use Your Information</h2>
        <p style={paragraphStyle}>
          We use the information we collect for the following purposes:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}><strong>Account Management:</strong> To create and manage your user account on the Website.</li>
          <li style={listItemStyle}><strong>Personalization:</strong> To display your profile image and other account-related information on your posts, comments, and interactions on the Website.</li>
          <li style={listItemStyle}><strong>Communication:</strong> To send you notifications related to your activity on the Website (e.g., responses to your posts, updates, etc.).</li>
          <li style={listItemStyle}><strong>Improvement of Services:</strong> To analyze usage patterns and feedback to improve the Website's functionality and user experience.</li>
        </ul>

        <h2 style={subHeadingStyle}>4. Sharing of Information</h2>
        <p style={paragraphStyle}>
          We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following situations:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}><strong>Legal Compliance:</strong> If required by law or in response to a valid legal request, we may disclose your information to authorities.</li>
          <li style={listItemStyle}><strong>Protection of Rights:</strong> To protect the rights, property, or safety of our users or the public, we may share your information when necessary.</li>
        </ul>

        <h2 style={subHeadingStyle}>5. Data Security</h2>
        <p style={paragraphStyle}>
          We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.
        </p>

        <h2 style={subHeadingStyle}>6. Your Rights</h2>
        <p style={paragraphStyle}>
          You have the right to access, update, or delete your personal information stored on our Website. You can do this by accessing your account settings or by contacting us directly at <a href="mailto:support@systemdesignexpert.com" style={emailStyle}>support@systemdesignexpert.com</a>.
        </p>

        <h2 style={subHeadingStyle}>7. Changes to This Privacy Policy</h2>
        <p style={paragraphStyle}>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
        </p>

        <h2 style={subHeadingStyle}>8. Contact Us</h2>
        <p style={paragraphStyle}>
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@systemdesignexpert.com" style={emailStyle}>support@systemdesignexpert.com</a>.
        </p>

        <h2 style={subHeadingStyle}>9. Consent</h2>
        <p style={paragraphStyle}>
          By using our Website, you consent to the terms of this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

// Styles
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px',
  backgroundColor: '#f9f9f9',
};

const contentStyle = {
  maxWidth: '800px',
  backgroundColor: '#ffffff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const headingStyle = {
  fontSize: '36px',
  marginBottom: '20px',
  color: '#333333',
};

const subHeadingStyle = {
  fontSize: '24px',
  marginBottom: '15px',
  color: '#333333',
};

const paragraphStyle = {
  fontSize: '18px',
  lineHeight: '1.6',
  color: '#555555',
  marginBottom: '15px',
};

const listStyle = {
  marginLeft: '20px',
  marginBottom: '15px',
};

const listItemStyle = {
  fontSize: '18px',
  color: '#555555',
  marginBottom: '10px',
};

const emailStyle = {
  color: '#007BFF',
  textDecoration: 'none',
};

export default PrivacyPolicy;
