import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AnswerForm from './AnswerForm';
import ExcalidrawWrapper from './ExcalidrawWrapper';
import { exportToBlob } from '@excalidraw/excalidraw';
import LoadingProgress from '../../components/progress/LoadingProgress';
import { useNavigate } from 'react-router-dom';
import FancyAlert from '../../components/alert/FancyAlert';
import API_ROUTES from '../apiRoutes';

const AddYourDesign = () => {
  const { questionId, tags } = useParams();
  const [alertVisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drawing, setDrawing] = useState(null);
  const [question, setQuestion] = useState({ title: '', body: '' });
  const [elements, setElements] = useState([]);
  const [titleError, setTitleError] = useState(''); // State for title validation error
  const [bodyError, setBodyError] = useState(''); // State for body validation error
  const [drawingError, setDrawingError] = useState(''); // State for drawing validation error
  const navigate = useNavigate();
  const excalidrawApiRef = useRef(null);

  const showAlert = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 3500);
  };

  useEffect(() => {
    if (excalidrawApiRef.current) {
      // Additional initialization logic if needed
    }
  }, [excalidrawApiRef.current]);

  const handleSave = async () => {
    // Reset any existing errors
    setTitleError('');
    setBodyError('');
    setDrawingError('');

    // Validation
    let valid = true;
    if (!question.title.trim()) {
      setTitleError('Title is required.');
      valid = false;
    }
    if (!question.body.trim()) {
      setBodyError('Description is required.');
      valid = false;
    }
    if (elements.length === 0) { // Check if any elements are present in the drawing
      setDrawingError('A drawing is required.');
      valid = false;
    }

    if (!valid) return; // Stop submission if validation fails

    setLoading(true);
    if (excalidrawApiRef.current) {
      const exportBlob = await exportToBlob({
        elements: elements,
        appState: excalidrawApiRef.current.getAppState(),
        mimeType: 'image/png',
        quality: 1,
        scale: 3,
        exportBackground: true,
        width: 800,
        height: 700,
      });
      const reader = new FileReader();
      reader.readAsDataURL(exportBlob);
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Strip out the data URI prefix
        setDrawing(base64String);
        handleSubmit(reader.result);
      };
    } else {
      handleSubmit(null);
    }
  };

  const handleChange = (elements, appState) => {
    setElements(elements);
  };

  const stripEmptyParagraphs = (html) => {
    return html.replace(/<p><\/p>|<p><br><\/p>/g, '');
  };

  const handleInputChange = (e) => {
    setQuestion({ ...question, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (drawingData) => {

   let questBody = stripEmptyParagraphs(question.body);

    await axios.post(API_ROUTES.HOST + '/give-answer', {
      title: question.title,
      body: questBody,
      drawing: drawingData,
      questionId: questionId,
      tags: tags
    }, {
      withCredentials: true,
      timeout: 10000
    }).then(response => {
      showAlert('Data fetched successfully!');
      setLoading(false);
      navigate('/home');
    }).catch(error => {
      console.error('There was an error adding the answer!', error);
      setLoading(false);
    });
  };

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <div>
      <br />
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <AnswerForm question={question} handleChange={handleInputChange} />
        {titleError && <p className="error-message">{titleError}</p>}
        {bodyError && <p className="error-message">{bodyError}</p>}
        <div>
          <div className="system-design-label">
            <span>Draw Your System Design</span>
          </div>
          <div className="excalidraw-wrapper" style={{ height: '500px' }}>
            <ExcalidrawWrapper
              ref={excalidrawApiRef}
              onChange={(elements, appState) => handleChange(elements, appState)}
            />
          </div>
          {drawingError && <p className="error-message">{drawingError}</p>}
          <div className="save-design">
            <button type="submit">Submit Design</button>
          </div>
        </div>
      </form>

      {alertVisible && (
        <FancyAlert
          message="Designed saved successfully!"
          duration={1500}
          onClose={() => {
            navigate('/home');
          }}
        />
      )}
    </div>
  );
};

export default AddYourDesign;