import React from 'react';

const AboutUs = () => {
  // Define the styles as JavaScript objects
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px',
    backgroundColor: '#f9f9f9',
  };

  const contentStyle = {
    maxWidth: '800px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const headingStyle = {
    fontSize: '36px',
    marginBottom: '20px',
    color: '#333333',
  };

  const paragraphStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555555',
    marginBottom: '15px',
  };

  const emailStyle = {
    color: '#007BFF',
    textDecoration: 'none',
  };

  const emailHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>About Us</h1>
        <p style={paragraphStyle}>
          Hello! I'm a passionate Software Engineer and enthusiastic developer
          who is always eager to learn new technologies and share knowledge with
          others. I believe in continuous learning and the power of collaboration.
        </p>
        <p style={paragraphStyle}>
          I developed this website as a platform for learning and sharing solutions
          to system design questions. System design is a crucial aspect of software
          development, especially in interviews and in building scalable, efficient
          systems in real-world applications.
        </p>
        <p style={paragraphStyle}>
          The goal of this platform is to bring together brilliant minds from all over
          the world to collaborate and create better system designs based on specific
          criteria. Whether you're preparing for an interview or looking to enhance your
          understanding of system design principles, this website serves as a valuable
          resource.
        </p>
        <p style={paragraphStyle}>
          There is rarely a "right" or "wrong" design, each approach has its own merits, trade-offs, and
          applications. The beauty of system design lies in its creativity. Different minds bring different
          perspectives, and it's this diversity of thought that leads to innovative and robust designs. 
          AI can provide a solid foundation and suggestions, but the most creative solutions often emerge from
          collaboration and the exchange of ideas between people with varied experiences and insights.
        </p>
        <p style={paragraphStyle}>
          This platform is designed to be more than just a resource - it's a community. Here, brilliant minds from
          around the world can share their unique approaches to system design, helping each other to refine and
          improve their ideas. Whether you’re a beginner or an experienced engineer, this is a place where you
          can contribute, learn, and grow.
        </p>
        <p style={paragraphStyle}>
          If you encounter any issues, have suggestions, or simply want to get in touch,
          please don't hesitate to reach out. You can contact me directly at
          <a
            href="mailto:support@systemdesignexpert.com"
            style={emailStyle}
            onMouseOver={(e) => (e.target.style = { ...emailStyle, ...emailHoverStyle })}
            onMouseOut={(e) => (e.target.style = emailStyle)}
          >
            {' '}
            support@systemdesignexpert.com
          </a>.
        </p>
        <p style={paragraphStyle}>Thank you for visiting, and happy learning!</p>
      </div>
    </div>
  );
};

export default AboutUs;
