import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './SystemDesignExchange.css'; // Import the CSS file
import API_ROUTES from './components/apiRoutes';

const SystemDesignExchange = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/home');
  };

 

  return (
    <div>
      <div>
      <img src="/sdelogo-cropped.svg" alt="image" width={329} height={320} className="sde-logo"/>
      </div>

      <div className="container-land">
        <h1 style={{color:'#386798'}}>Welcome to System Design Expert</h1>
        <p>
          Join our community of system design enthusiasts from around the world. 
          Ask your design questions and get valuable insights from experts. Vote on the best designs 
          and contribute to a growing repository of knowledge.
        </p>
        <div className="button-container-land">
          {/* <Link to="/ask-question" className="button-land">Start Asking Questions</Link> */}
          <button onClick={handleClick} className="button-land">Start Asking Questions</button>
        </div>
        <div className="features-land">
          <div className="feature-box-land">
            <h2>Ask for Designs</h2>
            <p>
              Have a complex system to design? Ask the community for their ideas and approaches. 
              You'll get a variety of designs to choose from, each with its own unique perspective.
            </p>
          </div>
          <div className="feature-box-land">
            <h2>Get Designs</h2>
            <p>
              Receive design suggestions from some of the brightest minds around the world. 
              Compare different designs and select the one that best fits your needs.
            </p>
          </div>
          <div className="feature-box-land">
            <h2>Vote on Designs</h2>
            <p>
              Help improve the community by voting on the best designs. Your vote helps identify 
              the most effective solutions and highlights them for others to see.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="footer-land">
        &copy; 2024 SystemDesignExpert.com | <Link to="/about">About Us</Link> | <Link to="/contact">Contact</Link> | <Link to="/policy">Privacy Policy</Link>
      </div> */}
    </div>
  );
};

export default SystemDesignExchange;
