import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './Navbar.css';
import API_ROUTES from '../apiRoutes';

const Navbar = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);  // Dropdown visibility state
  const searchBarRef = useRef(null);
  const resultsRef = useRef(null);
  const dropdownRef = useRef(null); // Ref for the dropdown menu

  const handleHome = () => {
    navigate('/home', { replace: true });
  };

  const handleAskQuestion = () => {
    navigate('/ask-question', { replace: true });
  };

  const handleProfileClick = () => {
    navigate('/profile', { replace: true });
  };

  const handleLogout = () => {
    sessionStorage.removeItem('authToken'); // Remove the JWT token
    setIsAuthenticated(false); // Update authentication state
    setUser(null); // Clear user data
    window.location.href = API_ROUTES.HOST + '/logout'; // Redirect to backend logout
  };

  const handleLogin = () => {
    window.location.href = API_ROUTES.HOST + '/login'; // Redirect to backend OAuth login
  };

  const handleSearch = (query) => {
    if (query.length >= 3) {
      axios.get(API_ROUTES.HOST + `/search?q=${encodeURIComponent(query)}`)
        .then((response) => {
          setSearchResults(response.data);
          setShowResults(true);
        })
        .catch((error) => {
          console.error('There was an error searching for questions!', error);
          setShowResults(false);
        });
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current && !dropdownRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }

    if (
      searchBarRef.current && !searchBarRef.current.contains(event.target) &&
      resultsRef.current && !resultsRef.current.contains(event.target)
    ) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      axios.get(API_ROUTES.HOST + '/api/verify-token', {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      })
        .then(response => {
          if (response.status === 200 && response.data.valid) {
            setUser(response.data.user);
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            sessionStorage.removeItem('authToken');
          }
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
          setIsAuthenticated(false);
          sessionStorage.removeItem('authToken');
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-top">
          <div className="navbar-right">
            <div style={{ display: 'flex' }}>
              <button onClick={handleHome} className="zindex-high">Home</button>
              <button onClick={handleAskQuestion} className="zindex-high">Ask Design Question</button>
              <div className="beta-tag">BETA</div>
              {isAuthenticated ? (
                <div style={{ marginLeft: 'auto', display: 'inline-flex', position: 'relative' }}>
                  <AccountCircleIcon
                    fontSize="large"
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleDropdownToggle}
                  />
                  {showDropdown && (
                    <div ref={dropdownRef} className="dropdown-menu">
                      <button onClick={handleProfileClick} className="dropdown-item">Profile</button>
                      {/* <button onClick={() => alert('Settings clicked')} className="dropdown-item">Settings</button> */}
                      <button onClick={handleLogout} className="dropdown-item">Logout</button>
                    </div>
                  )}
                </div>
              ) : (
                <button className="google-signin-button login-label" onClick={handleLogin} style={{ marginLeft: 'auto' }}>
                  <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" className="google-logo" />
                  Sign in with Google
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="header">
          <div>
            <img src="/logo-sde.png" alt="image" width={160} height={98} />
          </div>
          <div className="search-bar" ref={searchBarRef}>
            <SearchBar placeholder="Search for questions..." handleSearchChan={handleSearch} />
            {showResults && searchResults.length > 0 && (
              <div className="search-results-overlay" ref={resultsRef}>
                {searchResults.map((result) => (
                  <div key={result.id} className="search-result-item">
                    <Link to={`/question/${result.id}`}>
                      <h4 className="search-result-name">{result.question}</h4>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
