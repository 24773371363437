import React from 'react';
import './LoadingProgress.css';  // Assuming you're using a separate CSS file for styling

const LoadingProgress = () => {
  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p class="loading-p">Loading...</p>
    </div>
  );
};

export default LoadingProgress;
