import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tag from './tag/Tag';
import axios from 'axios';
import API_ROUTES from './apiRoutes';
import DOMPurify from 'dompurify';

const HighestVotedList = () => {
  const [answers, setHighestVotedAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the highest voted designs from the backend using axios
    const fetchHighestVotedAnswers = async () => {
      try {
        const response = await axios.get(API_ROUTES.HOST+'/top_voted_answers'); // Replace with your actual API endpoint
        setHighestVotedAnswers(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.response ? error.response.data.message : 'Error fetching highest voted designs');
        setLoading(false);
      }
    };

    fetchHighestVotedAnswers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getPublicImageUrl = (url) => {
    return url.replace(/\/(small|verysmall)$/, '/public');
  };

  return (
    <div>
      {answers.length > 0 ? (
        answers.map((answer) => {
          const cleanHTML = DOMPurify.sanitize(answer.body); // Sanitize the HTML content

          return (
            <Link
              to={`/answer/${answer.id}`}
              key={answer.id}
              className="card card-home txt-decoration-none"
              style={{ display: 'flex', justifyContent: 'space-between', textDecoration: 'none', color: 'inherit' }}
            >
              <div style={{ flex: '1' }}>
               
               <Link className='quest-on-top display-flex txt-decoration-none' to={`/question/${answer.question_id}`} >
               {/* <div className='m05em'><span className='text'>Asked Design : </span></div> */}
               <div> {answer.questtitle}</div>
               </Link> 
                <h3 className="card-color">{answer.title}</h3>
                <p className="font16px" dangerouslySetInnerHTML={{ __html: cleanHTML }} />
                <div className="tags block-tag">
                  {answer.tags && answer.tags.length > 0 && answer.tags.map((tag, index) => (
                    <Tag
                      key={`${answer.id}-${index}`}
                      label={tag.tag}
                      votes={tag.votes}
                      disableVoting={true}
                      removeVote={false}
                    />
                  ))}
                </div>
                <div className="font14px display-flex postedby-color">
                  <div className="marginTop24">
                    <span className="font-small">Posted By </span>: <strong>{answer.username}</strong> <br />
                    <small>{new Date(answer.created).toLocaleString()}</small>
                  </div>
                </div>
              </div>
              {answer.image_url && (
                <div className="drawing" style={{ marginLeft: '20px' }}>
                  <img 
                    src={getPublicImageUrl(answer.image_url)}
                    alt="System Design" 
                    style={{ maxWidth: '200px', height: 'auto' }} 
                  />
                </div>
              )}
            </Link>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No answers have been posted yet!</p>
      )}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default HighestVotedList;
