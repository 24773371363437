import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tag from '../tag/Tag'; // Ensure this path is correct
import '../../components/questions/QuestionList.css'; // Assuming you have a CSS file for additional styles
import DOMPurify from 'dompurify';

const AnswerPerQuestionList = ({ answers = [] }) => {

  const getPublicImageUrl = (url) => {
    return url.replace(/\/(small|verysmall)$/, '/public');
  };

  return (
    <div>
      {answers.length > 0 ? (

        answers.map((answer) => (
          <Link
            to={`/answer/${answer.id}`}
            key={answer.id}
            className="card card-home txt-decoration-none"
            style={{ display: 'flex', justifyContent: 'space-between', textDecoration: 'none', color: 'inherit' }}
          >
            <div style={{ flex: '1' }}>
              <h3 className="card-color">{answer.title}</h3>
                <div className="body">
                 <p className="font16px" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.body) }} />
               </div>
              <div className="tags block-tag">
                {answer.tags && answer.tags.length > 0 && answer.tags.map((tag, index) => (
                  <Tag
                    key={`${answer.id}-${index}`}
                    label={tag.tag}
                    votes={tag.votes}
                    disableVoting={true}
                    removeVote={false}
                  />
                ))}
              </div>
              <div className="font14px display-flex postedby-color">
                  <div className="marginTop24">
                    <span className="font-small">Posted By </span>: <strong>{answer.username}</strong> <br></br>
                    <small>{new Date(answer.created).toLocaleString()}</small>
                  </div>
                </div>
            </div>
            {answer.image_url && (
              <div className="drawing" style={{ marginLeft: '20px' }}>
                <img 
                  src={getPublicImageUrl(answer.image_url)}
                  alt="System Design" 
                  style={{ maxWidth: '200px', height: 'auto' }} 
                />
              </div>
            )}
            
          </Link>
        ))
      ) : (
        <p style={{ textAlign: 'center' }}>No answers have been posted yet!</p>
      )}
      {/* {loading && <p>Loading...</p>}
      {renderLoadMoreButton()} */}
    </div>
  );
};

export default AnswerPerQuestionList;
