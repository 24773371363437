import React, { useState, useEffect } from 'react';
import './FancyAlert.css';

const FancyAlert = ({ message, duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        setTimeout(onClose, 500); // Allow time for fade-out animation before calling onClose
      }
    }, duration);

    return () => clearTimeout(timeoutId);
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className="fancy-alert">
      {message}
    </div>
  );
};

export default FancyAlert;
