import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './UserProfile.css';
import API_ROUTES from '../apiRoutes';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';  // Import MUI Dialog components

const UserProfile = () => {
  const [profileData, setProfileData] = useState({
    user_info: {},
    questions: [],
    answers: [],
    total_questions: 0,
    total_answers: 0,
  });

  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingAnswerId, setEditingAnswerId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false); // State for controlling dialog visibility
  const [deleteTarget, setDeleteTarget] = useState({ type: '', id: null }); // State for storing what to delete (question or answer)
  
  const [questionOffset, setQuestionOffset] = useState(0);
  const [answerOffset, setAnswerOffset] = useState(0);
  const limit = 5;

  const location = useLocation();

  useEffect(() => {
    fetchProfileData(0, 0, 'initial');
  }, [location]);

  const fetchProfileData = (questionOffset, answerOffset, loadType) => {
    axios.get(API_ROUTES.HOST + `/user/profile?limit=${limit}&question_offset=${questionOffset}&answer_offset=${answerOffset}`, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("authToken")}`, 
        'userid': sessionStorage.getItem("userid"),
        'username': sessionStorage.getItem("username")
      }
    })
    .then(response => {
      if (loadType === 'questions') {
        setProfileData(prevData => ({
          ...prevData,
          questions: [...prevData.questions, ...response.data.questions],
          total_questions: response.data.total_questions,
        }));
      } else if (loadType === 'answers') {
        setProfileData(prevData => ({
          ...prevData,
          answers: [...prevData.answers, ...response.data.answers],
          total_answers: response.data.total_answers,
        }));
      } else {
        setProfileData(response.data);
      }
    })
    .catch(error => {
      console.error('There was an error fetching the profile data!', error);
    });
  };

  const loadMoreQuestions = () => {
    setQuestionOffset(prevOffset => {
      const newOffset = prevOffset + limit;
      fetchProfileData(newOffset, answerOffset, 'questions');
      return newOffset;
    });
  };

  const loadMoreAnswers = () => {
    setAnswerOffset(prevOffset => {
      const newOffset = prevOffset + limit;
      fetchProfileData(questionOffset, newOffset, 'answers');
      return newOffset;
    });
  };

  const handleEditQuestion = (questionId) => {
    setEditingQuestionId(questionId);
    const question = profileData.questions.find(q => q.id === questionId);
    if (question) {
      setEditContent(question.question);
    }
  };

  const handleEditAnswer = (answerId) => {
    setEditingAnswerId(answerId);
    const answer = profileData.answers.find(a => a.id === answerId);
    if (answer) {
      setEditContent(answer.body);
    }
  };

  const handleSaveEdit = () => {
    if (editingQuestionId) {
      axios.put(API_ROUTES.HOST+`/questions/${editingQuestionId}`, {
        question: editContent
      }, {
        withCredentials: true
      })
      .then(response => {
        setProfileData(prevData => ({
          ...prevData,
          questions: prevData.questions.map(q => 
            q.id === editingQuestionId ? response.data.question : q
          )
        }));
        setEditingQuestionId(null);
        setEditContent('');
      })
      .catch(error => {
        console.error('There was an error saving the edited question!', error);
      });
    } else if (editingAnswerId) {
      axios.put(API_ROUTES.HOST+`/answers/${editingAnswerId}`, {
        body: editContent
      }, {
        withCredentials: true
      })
      .then(response => {
        setProfileData(prevData => ({
          ...prevData,
          answers: prevData.answers.map(a => 
            a.id === editingAnswerId ? response.data.answer : a
          )
        }));
        setEditingAnswerId(null);
        setEditContent('');
      })
      .catch(error => {
        console.error('There was an error saving the edited answer!', error);
      });
    }
  };

  const handleDeleteQuestion = (questionId) => {
    setDeleteTarget({ type: 'question', id: questionId });
    setDialogOpen(true); // Open the confirmation dialog
  };

  const handleDeleteAnswer = (answerId) => {
    setDeleteTarget({ type: 'answer', id: answerId });
    setDialogOpen(true); // Open the confirmation dialog
  };

  const confirmDelete = () => {
    const { type, id } = deleteTarget;
    if (type === 'question') {
      axios.delete(API_ROUTES.HOST+`/questions/${id}`, {
        withCredentials: true
      })
      .then(() => {
        setProfileData(prevData => ({
          ...prevData,
          questions: prevData.questions.filter(q => q.id !== id)
        }));
      })
      .catch(error => {
        console.error('There was an error deleting the question!', error);
      });
    } else if (type === 'answer') {
      axios.delete(API_ROUTES.HOST+`/answers/${id}`, {
        withCredentials: true
      })
      .then(() => {
        setProfileData(prevData => ({
          ...prevData,
          answers: prevData.answers.filter(a => a.id !== id)
        }));
      })
      .catch(error => {
        console.error('There was an error deleting the answer!', error);
      });
    }
    setDialogOpen(false);
  };

  const cancelDelete = () => {
    setDialogOpen(false);
    setDeleteTarget({ type: '', id: null });
  };

  const getsmallImage = (url) => {
    return url.replace(/\/(verysmall|public)$/, '/small');
  };

  return (
    <div className="user-profile">
      {profileData && profileData.user_info && profileData.user_info.user_info && (
        <div className="user-info">
          <img 
            src={profileData.user_info.user_info.picture} 
            alt="User profile" 
            className="user-photo" 
          />
          <h2>{profileData.user_info.user_info.name}</h2>
        </div>
      )}
      <div className="user-content-container">
        <div className="user-questions-column">
          <h3>Asked Questions</h3>
          {profileData.questions.map(question => (
            <div key={question.id} className="user-question">
              {editingQuestionId === question.id ? (
                <>
                  {/* <ReactQuill value={editContent} onChange={setEditContent} /> */}
                  <textarea value={editContent} onChange={(e) => setEditContent(e.target.value)} />
                  <button onClick={handleSaveEdit}>Save</button>
                  <button onClick={() => setEditingQuestionId(null)} style={{ marginLeft: '4px' }}>Cancel</button>
                </>
              ) : (
                <>
                  <Link to={`/question/${question.id}`} className="txt-decoration-none">
                    <p className="clickable-text">{question.title}</p><br/>
                    <p className="clickable-text">{question.question}</p>
                  </Link>
                  <div className="icons">
                    <EditIcon fontSize="small" onClick={() => handleEditQuestion(question.id)} />
                    <DeleteIcon fontSize="small" onClick={() => handleDeleteQuestion(question.id)} />
                  </div>
                </>
              )}
            </div>
          ))}
          {profileData.questions.length > 0 && profileData.questions.length < profileData.total_questions && (
            <button onClick={loadMoreQuestions}>Load More Questions</button>
          )}
        </div>
        <div className="user-answers-column">
          <h3>Given Answers</h3>
          {profileData.answers.map(answer => (
            <div key={answer.id} className="user-answer">
              {editingAnswerId === answer.id ? (
                <>
                  <ReactQuill value={editContent} onChange={setEditContent} />
                  <button onClick={handleSaveEdit}>Save</button>
                  <button onClick={() => setEditingAnswerId(null)} style={{ marginLeft: '4px' }}>Cancel</button>
                </>
              ) : (
                <>
                  <h4>{answer.title}</h4>
                  <Link to={`/answer/${answer.id}`} className="txt-decoration-none">
                    <div className="body clickable-text">
                      <p className="font16px" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.body) }} />
                    </div>
                    <img 
                      src={getsmallImage(answer.image_url)}
                      alt="System Design" 
                      style={{ maxWidth: '500px', height: 'auto' }} 
                    />
                  </Link>
                  <div className="icons">
                    <EditIcon fontSize="small" onClick={() => handleEditAnswer(answer.id)} />
                    <DeleteIcon fontSize="small" onClick={() => handleDeleteAnswer(answer.id)} />
                  </div>
                </>
              )}
            </div>
          ))}
          {profileData.answers.length > 0 && profileData.answers.length < profileData.total_answers && (
            <button onClick={loadMoreAnswers}>Load More Answers</button>
          )}
        </div>
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={cancelDelete}
      >
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete this {deleteTarget.type === 'question' ? 'question' : 'answer'}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserProfile;
