import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './CommentSection.css';
import API_ROUTES from '../apiRoutes';

const CommentSection = ({ answerId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editContent, setEditContent] = useState('');

  // Retrieve the logged-in username from sessionStorage
  const loggedInUsername = sessionStorage.getItem('username');

  useEffect(() => {
    // Fetch existing comments for the answer
    axios.get(API_ROUTES.HOST+`/comments/${answerId}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the comments!', error);
      });
  }, [answerId]);

  const handleCommentSubmit = () => {
    if (!newComment.trim()) return;

    // Post a new comment
    axios.post(API_ROUTES.HOST+'/comment', {
        answerId: answerId,
        content: newComment
    }, {
      withCredentials: true
    })
    .then(response => {
      setComments([...comments, response.data]);
      setNewComment('');
    })
    .catch(error => {
      console.error('There was an error posting the comment!', error);
    });
  };

  const handleEditClick = (comment) => {
    setEditingCommentId(comment.id);
    setEditContent(comment.content);
  };

  const handleEditSubmit = () => {
    axios.put(API_ROUTES.HOST+`/comment/${editingCommentId}`, {
      content: editContent,
      answerId: answerId,
    }, {
      withCredentials: true
    })
    .then(response => {
      setComments(comments.map(comment => 
        comment.id === editingCommentId ? response.data : comment
      ));
      setEditingCommentId(null);
      setEditContent('');
    })
    .catch(error => {
      console.error('There was an error editing the comment!', error);
    });
  };

  const handleDelete = (commentId) => {
    axios.delete(API_ROUTES.HOST+`/comment/${commentId}`, {
        params: {
          answerId: answerId,  // Add answerId as a query parameter
        },
        withCredentials: true
      })
    .then(() => {
      setComments(comments.filter(comment => comment.id !== commentId));
    })
    .catch(error => {
      console.error('There was an error deleting the comment!', error);
    });
  };

  return (
    <div className="comment-section">
      <h4>Comments</h4>
      {comments.length === 0 ? <p className="font14px">No comments yet. Be the first to comment!</p> : ''}
      <div className="comment-form">
        <textarea
          className="comment-form-textarea"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
          rows="3"
        />
        <button onClick={handleCommentSubmit}>Submit</button>
      </div>
      <div className="comment-list">
        {comments.length > 0 && comments.map(comment => (
          <div key={comment.id} className="comment">
            {editingCommentId === comment.id ? (
              <>
                <textarea
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                  rows="2"
                />
                <button onClick={handleEditSubmit}>Save</button>
                <button onClick={() => setEditingCommentId(null)}>Cancel</button>
              </>
            ) : (
              <>
                <div className="font14px display-flex">
                  <div>
                    <strong className="font-small">{comment.username}</strong>: {comment.content}
                    <small>{new Date(comment.timestamp).toLocaleString()}</small>
                  </div>
                  {comment.username === loggedInUsername && (
                    <div className="comment-icons">
                      <EditIcon fontSize="small" onClick={() => handleEditClick(comment)} />
                      <DeleteIcon fontSize="small" onClick={() => handleDelete(comment.id)} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentSection;
