import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';

const ExcalidrawWrapper = forwardRef((props, ref) => {
  const localRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getSceneElements: () => {
      return localRef.current?.getSceneElements();
    },
    getAppState: () => {
      return localRef.current?.getAppState();
    }
  }));

  return <Excalidraw ref={localRef} {...props}/>;
});

export default ExcalidrawWrapper;
