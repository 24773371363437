import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tag from '../tag/Tag'; // Ensure this path is correct
import '../../components/questions/QuestionList.css'; // Assuming you have a CSS file for additional styles
import axios from 'axios';
import API_ROUTES from '../apiRoutes';
import DOMPurify from 'dompurify';

const AnswerList = () => {
  const [answers, setAnswers] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const limit = 5; // Default to 5 items per page

  useEffect(() => {
    fetchAnswers(start);
  }, [start]);

  const fetchAnswers = async (start) => {
    setLoading(true);
    try {
      const end = start + limit - 1; // Calculate the end index
      const response = await axios.get(API_ROUTES.HOST + '/get-answers', {
        params: {
          start: start,
          end: end,
        },
        withCredentials: true,
      });

      const data = response.data.map((answer) => ({
        ...answer,
        tags: Array.isArray(answer.tags) ? answer.tags : [answer.tags],
      }));
      setAnswers((prevAnswers) => [...prevAnswers, ...data]);
      setHasMore(data.length === limit); // Check if there are more answers to load
    } catch (error) {
      console.error('Error fetching answers:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setStart((prevStart) => prevStart + limit);
  };

  const renderLoadMoreButton = () => {
    if (hasMore && !loading) {
      return (
        <div>
          <button onClick={loadMore} className="load-more-button">
            Load More
          </button>
        </div>
      );
    }
    return null;
  };
  
  const getPublicImageUrl = (url) => {
    return url.replace(/\/(small|verysmall)$/, '/public');
  };

  return (
    <div>
      {answers.length > 0 ? (
        answers.map((answer) => {
          const cleanHTML = DOMPurify.sanitize(answer.body); // Sanitize the HTML content

          return (
            <Link
              to={`/answer/${answer.id}`}
              key={answer.id}
              className="card card-home txt-decoration-none"
              style={{ display: 'flex', justifyContent: 'space-between', textDecoration: 'none', color: 'inherit' }}
            >
              <div style={{ flex: '1' }}>
               
               <Link className='quest-on-top display-flex txt-decoration-none' to={`/question/${answer.question_id}`} >
               {/* <div className='m05em'><span className='text'>Asked Design : </span></div> */}
               <div> {answer.questtitle}</div>
               </Link> 
                <h3 className="card-color">{answer.title}</h3>
                <p className="font16px" dangerouslySetInnerHTML={{ __html: cleanHTML }} />
                <div className="tags block-tag">
                  {answer.tags && answer.tags.length > 0 && answer.tags.map((tag, index) => (
                    <Tag
                      key={`${answer.id}-${index}`}
                      label={tag.tag}
                      votes={tag.votes}
                      disableVoting={true}
                      removeVote={false}
                    />
                  ))}
                </div>
                <div className="font14px display-flex postedby-color">
                  <div className="marginTop24">
                    <span className="font-small">Posted By </span>: <strong>{answer.username}</strong> <br />
                    <small>{new Date(answer.created).toLocaleString()}</small>
                  </div>
                </div>
              </div>
              {answer.image_url && (
                <div className="drawing" style={{ marginLeft: '20px' }}>
                  <img 
                    src={getPublicImageUrl(answer.image_url)}
                    alt="System Design" 
                    style={{ maxWidth: '200px', height: 'auto' }} 
                  />
                </div>
              )}
            </Link>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No answers have been posted yet!</p>
      )}
      {loading && <p>Loading...</p>}
      {renderLoadMoreButton()}
    </div>
  );
};

export default AnswerList;
