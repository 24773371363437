import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import API_ROUTES from './apiRoutes';
import GoogleLoginCard from '../components/util/GoogleLoginCard'; 

const PrivateRoute = ({ element: Element, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates loading state
  const [isLoading, setIsLoading] = useState(true); // Start with loading
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');

    if (!token) {
      // No token found, set isAuthenticated to false and stop loading
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    // Verify the token with the backend
    axios.post(`${API_ROUTES.HOST}/api/verify-token`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      if (response.status === 200 && response.data.valid) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false); // Explicitly set to false if the token is invalid
      }
    })
    .catch(error => {
      console.error('Token verification failed:', error);
      setIsAuthenticated(false); // Explicitly set to false on error
    })
    .finally(() => {
      setIsLoading(false); // Stop loading after the request is complete
    });
  }, [navigate, location]);

  if (isLoading) {
    return <div>Loading...</div>; // Show loading spinner or message
  }

  return isAuthenticated ? <Element {...rest} /> : <GoogleLoginCard />;
};

export default PrivateRoute;
