import React, { useState, useEffect } from 'react';
import './SearchBar.css';

const SearchBar = ({ placeholder, handleSearchChan }) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    handleSearchChan(query);
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchBar;
