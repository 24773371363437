import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const Tag = ({ label, votes, onVote, disableVoting = false , removeVote = false}) => {
  let voteClass = '';

  if (!votes) {
    voteClass = 'low-votes';
  } else if (votes < 10) {
    voteClass = 'low-votes';
  } else if (votes <= 50) {
    voteClass = 'medium-votes';
  } else {
    voteClass = 'high-votes';
  }

  const handleVoteClick = (e) => {
    e.stopPropagation();
    if (disableVoting) {
      // console.log("Voting is disabled for this tag.");
      return;
    }
    // console.log("vote clicked...");
    onVote();
  };

  return (
    <div className="tag">
      <span className="tag-label">{label}</span>
      {removeVote}
      {!removeVote && 
      <span 
        className={`tag-votes ${voteClass}`} 
        onClick={handleVoteClick}
        style={{ cursor: disableVoting ? 'default' : 'pointer', display: 'flex', alignItems: 'center' }}
      >

        <ThumbUpIcon fontSize="small" style={{ marginRight: '4px' }} />
        {votes}
      </span>
      }
    </div>
  );
};

export default Tag;
