import React from 'react';
import { useNavigate } from 'react-router-dom';

const AskQuestionButton = ({ questionId, tagIndices }) => {
  // console.log("tagId alok ",tagIndices)
  const navigate = useNavigate();

  const navigateToAskQuestion = () => {
    const tagValues = tagIndices.map((value) => value).join(',');
    // console.log("tagKeys ",tagValues)
    navigate(`/add-question/${questionId}/${tagValues}`);
  };

  return (
    <div className="add-design-button">
      {/* Other content */}
      <button onClick={navigateToAskQuestion}>Add your Design</button>
      {/* Other content */}
    </div>
  );
};

export default AskQuestionButton;
