const REACT_APP_HOST = process.env.REACT_APP_HOST;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const API_ROUTES = {
  HOST: `${REACT_APP_HOST}`,
  ADMIN_TOKEN: `${ADMIN_TOKEN}`
    // Add more  url and use it later anywhere
  // CREATE_USER: `${API_BASE_URL}/api/v1/users`,
  // UPDATE_USER: `${API_BASE_URL}/api/v1/users/update`,
  // DELETE_USER: `${API_BASE_URL}/api/v1/users/delete`,
  // GET_RESOURCE: `${API_BASE_URL}/api/v1/resource`,
  // CREATE_USER: `${API_BASE_URL}/api/v1/users`,
  // UPDATE_USER: `${API_BASE_URL}/api/v1/users/update`,
  // DELETE_USER: `${API_BASE_URL}/api/v1/users/delete`,
  // GET_RESOURCE: `${API_BASE_URL}/api/v1/resource`,
  // CREATE_USER: `${API_BASE_URL}/api/v1/users`,
  // UPDATE_USER: `${API_BASE_URL}/api/v1/users/update`,
  // DELETE_USER: `${API_BASE_URL}/api/v1/users/delete`

};

export default API_ROUTES;