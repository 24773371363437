import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import API_ROUTES from './apiRoutes';

const Callback = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        if (code) {
            fetch(API_ROUTES.HOST + code)
                .then(response => response.json())
                .then(data => {
                    // console.log('Token received:', data);
                    // Redirect to another page or handle the response
                })
                .catch(error => console.error('Error:', error));
        }
    }, [location]);

    return (
        <div>
            <h1>Processing your request...</h1>
        </div>
    );
};

export default Callback;
