import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Tag from '../tag/Tag'; // Ensure this path is correct
import AskQuestionButton from './AskQuestionButton';
import AnswerPerQuestionList from '../../components/answers/AnswerPerQuestionList'; // Assuming you have AnswerList in the same directory
import API_ROUTES from '../apiRoutes';

const QuestionDetail = () => {
  const { quest_id } = useParams();
  const [question, setQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [answerPage, setAnswerPage] = useState(1);
  const [hasMoreAnswers, setHasMoreAnswers] = useState(true);
  const answersPerPage = 10;

  useEffect(() => {
    axios.get(API_ROUTES.HOST+`/question/${quest_id}`)
      .then((response) => {
        setQuestion(response.data);
        fetchAnswers(quest_id, 1); // Load the first page of answers
      })
      .catch((error) => {
        console.error('There was an error fetching the question!', error);
      });
  }, [quest_id]);

  const fetchAnswers = (quest_id, page) => {
    axios.get(API_ROUTES.HOST+`/question/${quest_id}/answers?page=${page}&limit=${answersPerPage}`)
      .then((response) => {
        const fetchedAnswers = response.data;

        // Filter out duplicates
        setAnswers(prevAnswers => {
          const existingAnswerIds = new Set(prevAnswers.map(answer => answer.id));
          const newAnswers = fetchedAnswers.filter(answer => !existingAnswerIds.has(answer.id));
          return [...prevAnswers, ...newAnswers];
        });

        if (fetchedAnswers.length < answersPerPage) {
          setHasMoreAnswers(false);
        }
      })
      .catch((error) => {
        console.error('There was an error fetching the answers!', error);
      });
  };

  const loadMoreAnswers = () => {
    const nextPage = answerPage + 1;
    fetchAnswers(quest_id, nextPage);
    setAnswerPage(nextPage);
  };

  if (!question) {
    return <div>No Question as of now</div>;
  }

  return (
    <>
      <div className="card" key={question.id}>
        <div>
          <h3>
            <div className="card-color txt-decoration-none">
              {question.question}
            </div>
          </h3>
          <div className="tags block-tag">
            <span className="criteria">Criteria asked to consider while designing</span>
            {question?.tags?.length > 0 && question.tags.map((tag, index) => (
              <Tag key={`${tag}`} label={tag} disableVoting={true} removeVote={true}/>
            ))}
            <AskQuestionButton questionId={question.id} tagIndices={question.tags} />
          </div>
          <div className="font14px display-flex postedby-color">
                  <div className="marginTop24">
                    <span className="font-small">Posted By </span>: <strong>{question.username}</strong> <br></br>
                    <small>{new Date(question.created).toLocaleString()}</small>
                  </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="posted-label">Posted Designs For Above Question:</h3>
        <AnswerPerQuestionList answers={answers} />
        {hasMoreAnswers && (
          <button onClick={loadMoreAnswers}>Load More Answers</button>
        )}
      </div>
    </>
  );
};

export default QuestionDetail;
