import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Link } from 'react-router-dom';
import Navbar from './components/util/Navbar.js';
import ContactUs from './components/ContactUs.js';

import Home from './components/Home';
import AddYourDesign from './components/answers/AddYourDesign';
import QuestionDetail from './components/questions/QuestionDetail.js';
import AskQuestion from './components/questions/AskQuestion';
import FullAnswer from './components/answers/FullAnswer';
import UserProfile from './components/profile/UserProfile.js';
import PrivateRoute from './components/PrivateRoute';
import SystemDesignExchange from './SystemDesignExchange';
import './App.css';
import AdminPanel from './components/AdminPanel';
import Callback from './components/Callback';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';


import axios from 'axios';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Capture the token from URL parameters if it exists
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const username = params.get('username');
    const userid = params.get('userid');

    if(userid){
      sessionStorage.setItem('userid', userid);
    }

    if(username){
      sessionStorage.setItem('username', username);
    }

    if (token) {
      // Store the token in sessionStorage
      sessionStorage.setItem('authToken', token);

      // Optionally, remove the token from the URL to keep it clean
      navigate(location.pathname, { replace: true });

      // Refresh the page to update the UI
      window.location.reload();
    }

    // Add an axios interceptor to attach the token and other headers to every request
    const interceptor = axios.interceptors.request.use(
      config => {
        const authToken = sessionStorage.getItem('authToken');
        const userId = sessionStorage.getItem('userid');
        const username = sessionStorage.getItem('username');

        if (authToken) {
          config.headers['Authorization'] = `Bearer ${authToken}`;
        }

        if (userId) {
          config.headers['userid'] = userId;
        }

        if (username) {
          config.headers['username'] = username;
          config.headers['given_name'] = username;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => {
      axios.interceptors.request.eject(interceptor);
    };

  }, [location, navigate]);

  return (
    <div className="container">
      {location.pathname !== '/' && <Navbar />}
      <div className="content">
        <Routes>
          <Route path="/" element={<SystemDesignExchange />} />
          <Route path="/home" element={<Home />} />
          <Route path="/add-question/:questionId/:tags" element={<PrivateRoute element={AddYourDesign} />} />
          <Route path="/question/:quest_id" element={<PrivateRoute element={QuestionDetail} />} />
          <Route path="/ask-question" element={<PrivateRoute element={AskQuestion} />} />
          <Route path="/answer/:answerId" element={<PrivateRoute element={FullAnswer} />} />
          <Route path="/profile" element={<PrivateRoute element={UserProfile} />} />
          <Route path="/adminwa" element={<AdminPanel/>} />
          <Route path="/callback" element={<Callback/>} />
          <Route path="/contact" element={<ContactUs/>}/>
          <Route path="/about" element={<AboutUs/>}/>
          <Route path="/policy" element={<PrivacyPolicy/>}/>

          
        </Routes>  
        <div className="footer-land">
        &copy; 2024 SystemDesignExpert.com | <Link to="/about">About Us</Link> | <Link to="/contact">Contact</Link> | <Link to="/policy">Privacy Policy</Link>
      </div>
      </div>
    </div>
  );
};

export default App;
