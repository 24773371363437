import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_ROUTES from './apiRoutes';

const AdminPanel = () => {
    const [pendingItems, setPendingItems] = useState({
        pending_questions: [],
        pending_answers: []
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 10;

    

    const adminToken = API_ROUTES.ADMIN_TOKEN;  // Access the token from environment variables

    useEffect(() => {
        fetchPendingItems(currentPage);
    }, [currentPage]);

    const fetchPendingItems = async (page) => {
        try {
            const response = await axios.get(API_ROUTES.HOST+'/admin/pending-items', {
                params: { page, page_size: pageSize }
            });
            setPendingItems(response.data);
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.error("Error fetching pending items:", error);
        }
    };

    const clearingFun = async () => {
        try {
            const response = await axios.get(API_ROUTES.HOST+'/admin/dbdel');
            console.log(response)

        } catch (error) {
            console.error("Error :", error);
        }
    };

    const updateStatus = async (id, type, status) => {
        try {
            await axios.post(API_ROUTES.HOST+'/admin/update-status', {
                id,
                type,
                status
            });
            fetchPendingItems(currentPage);  // Refresh the list after updating status
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div>
            <h2>Admin Panel - Pending Items</h2>

            <h3>Pending Questions</h3>
            {pendingItems.pending_questions.length === 0 ? (
                <p>No pending questions.</p>
            ) : (
                <ul>
                    {pendingItems.pending_questions.map(question => (
                        <li key={question.id}>
                            <strong>{question.title}</strong><br />
                            <strong>{question.question}</strong><br />
                            <button onClick={() => updateStatus(question.id, 'question', 'approved')}>Approve</button><br></br>
                            <button onClick={() => updateStatus(question.id, 'question', 'rejected')}>Reject</button>
                        </li>
                    ))}
                    { <div><br/><br/><button onClick={() => clearingFun()}>Clearing</button> do not click!!! </div> }
                </ul>
            )}

            <h3>Pending Answers</h3>
            {pendingItems.pending_answers.length === 0 ? (
                <p>No pending answers.</p>
            ) : (
                <ul>
                    {pendingItems.pending_answers.map(answer => (
                        <li key={answer.id}>
                            <strong>{answer.title}</strong><br />
                            <p>{answer.body}</p>
                            <img 
                  src={answer.image_url} 
                  alt="System Design" 
                  style={{ maxWidth: '200px', height: 'auto' }} 
                /><br/>
                            <button onClick={() => updateStatus(answer.id, 'answer', 'approved')}>Approve</button>
                            <button onClick={() => updateStatus(answer.id, 'answer', 'rejected')}>Reject</button>
                        </li>
                    ))}
                </ul>
            )}

            <div>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span> Page {currentPage} of {totalPages} </span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default AdminPanel;
