import React, { useState } from 'react';
import Select from 'react-select';
import './AskQuestion.css';
import axios from 'axios';
import LoadingProgress from '../../components/progress/LoadingProgress';
import { useNavigate } from 'react-router-dom';
import FancyAlert from '../../components/alert/FancyAlert';
import API_ROUTES from '../apiRoutes';

const criteriaOptions = [
  {  label: 'Highly Available', value:'HA' },
  {  label: 'Fault Tolerant', value:'FT'},
  {  label: 'Scalability', value:'SC' },
  {  label: 'Cost Efficiency', value:'CF' },
  {  label: 'Performance', value:'PE' },
  {  label: 'Reliability', value:'RE' },
  {  label: 'Security', value:'SE' },
  {  label: 'Maintainability', value:'MA' },
  {  label: 'Extensibility', value:'EX' },
  {  label: 'Resilience', value:'RES' },
  {  label: 'Compliance' , value:'COMP'},
];


const AskQuestion = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);  
  const [question, setQuestion] = useState('');
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [questionError, setQuestionError] = useState(''); // State for question validation error
  const [criteriaError, setCriteriaError] = useState(''); // State for criteria validation error
  const navigate = useNavigate();

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
    if (questionError) setQuestionError(''); // Clear error when user starts typing
  };

  const handleCriteriaChange = (selectedOptions) => {
    setSelectedCriteria(selectedOptions);
    if (criteriaError) setCriteriaError(''); // Clear error when criteria is selected
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem('authToken');
    if (!token) {
      navigate('/login'); // Redirect to login if no token found
      return;
    }

    // Validation
    let valid = true;
    if (!question.trim()) {
      setQuestionError('Question is required.');
      valid = false;
    }
    if (selectedCriteria.length === 0) {
      setCriteriaError('At least one criteria must be selected.');
      valid = false;
    }

    if (!valid) return; // Stop submission if validation fails

    setLoading(true);
    const tags = selectedCriteria.map(option => option.label);

    axios.post(API_ROUTES.HOST + '/questions/', 
      { question, tags },
      { 
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true  
      }
    )
    .then(response => {
      showAlert('Question saved successfully!');
      setQuestion('');
      setSelectedCriteria([]);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error adding question:', error);
      setLoading(false);
    });
  };

  if (loading) {
    return <LoadingProgress />;  // Show loading spinner while loading
  }

  const showAlert = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
      navigate('/home');  // Navigate to the home page after the alert closes
    }, 3500);
  };

  return (
    <div className="ask-question">
      <h2>Ask a Design Question</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="question">Question:</label>
          <input
            type="text"
            id="question"
            value={question}
            onChange={handleQuestionChange}
            placeholder="Design Youtube..."
            required
          />
          {questionError && <p className="error-message">{questionError}</p>}
        </div>
        <div className="input-group">
          <label htmlFor="criteria">Criteria:</label>
          <Select
            id="criteria"
            isMulti
            options={criteriaOptions}
            value={selectedCriteria}
            onChange={handleCriteriaChange}
            className="multi-select"
            classNamePrefix="select"
            placeholder="Select criteria..."
          />
          {criteriaError && <p className="error-message">{criteriaError}</p>}
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>

      {alertVisible && (
        <FancyAlert
          message="Question saved successfully!"
          duration={1500}
          onClose={() => navigate('/home')}
        />
      )}
    </div>
  );
};

export default AskQuestion;
