import React from 'react';
import './GoogleLoginCard.css';
import API_ROUTES from '../apiRoutes';

const googleLogin = () => {
  window.location.href = API_ROUTES.HOST + '/login'; // Redirect to backend OAuth login
};
const GoogleLoginCard = () => {
  return (
    <div className="google-login-card">
      <h2>Please Login with Google</h2>
      <p>To view this services, please log in with your Google account.</p>
      <button className="google-signin-button login-label" onClick={googleLogin} style={{ display: 'inline' }}>
                <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" className="google-logo" />
                Sign in with Google
              </button>
    </div>
  );
};

export default GoogleLoginCard;
