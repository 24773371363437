import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const AnswerForm = ({ question, handleChange }) => {

  const handleQuillChange = (content) => {
    handleChange({
      target: {
        name: 'body',
        value: content,
      },
    });
  };

  return (
    <div>
      <input 
        type="text" 
        name="title" 
        placeholder="Design Title" 
        value={question.title} 
        onChange={handleChange} 
        maxLength={400} 
        className='title-quest' 
      /><br/><br/>
      
      <ReactQuill 
        value={question.body} 
        onChange={handleQuillChange} 
        className='txtarea' 
        placeholder="Design Description" 
      /><br/><br/>
    </div>
  );
};

export default AnswerForm;
