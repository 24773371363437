import React, { useEffect, useState } from 'react';

import AnswerList from '../components/answers/AnswerList';
import QuestionList from './questions/QuestionList'; // Assuming this is your recent design answers component
import HighestVotedList from '../components/HighestVotedList'; // Assuming you have a component to list highest voted designs

import '../Home.css';

const Home = () => {

  const [questions, setQuestions] = useState([]);
  const [view, setView] = useState('answers'); // Default view


  const handleNewQuestion = (newQuestion) => {
    setQuestions([newQuestion, ...questions]);
  };

  const toggleView = (viewType) => {
    setView(viewType);
  };

  return (
    <div className='content100'>
      <div className="button-group">
        <button className={`toggle-button ${view === 'answers' ? 'active' : ''}`} onClick={() => toggleView('answers')}>
          Recent Design Answers
        </button>
        <button className={`toggle-button ${view === 'questions' ? 'active' : ''}`} onClick={() => toggleView('questions')}>
          Recent Asked Designs
        </button>
        <button className={`toggle-button ${view === 'highest' ? 'active' : ''}`} onClick={() => toggleView('highest')}>
          Highest Voted Designs
        </button>
      </div>
      {view === 'questions' ? (
        <div>
          <QuestionList />
        </div>
      ) : view === 'highest' ? (
        <HighestVotedList />
      ) : (
        <AnswerList />
      )}
    </div>
  );
};

export default Home;
